import React, { useState, useEffect } from 'react'
import App from 'base-shell/lib'
//import MUIConfig from 'material-ui-shell/lib';
//import merge from 'base-shell/lib/utils/config';
import _config from './config'
import { useLocalStorage } from 'react-use';


// MainAppContext
import { MainContext } from './config/mainContext';
// Apollo Wrapper for default client
import ApolloWrapper from './config/graphql/clients';
// TODO: Sweet Alert
//import AlertContainer from './components/SweetAlert/AlertContainer';
// TODO: Loader Layer
//import LoaderContainer from './components/LoaderLayer/LoaderContainer';
// Bootstrap Styles ???
//import 'bootstrap/dist/css/bootstrap.min.css';
// Block-UI Styles ???
//import 'react-block-ui/style.css';

// Merge app config (rmv previous version)
//const config = merge(MUIConfig, _config);
export default function Demo() {
  const [localStorageToken, setLocalStorageToken] = useLocalStorage('token', '');
  const [token, setToken] = useState(localStorageToken);
  useEffect(() => {
    if (localStorageToken != token) {
      console.info("UPDATE LOCAL STORAGE TOKEN:", token)
      setLocalStorageToken(token);
    }
  }, [token])

  return (
    <MainContext.Provider value={{ token, setToken }}>
      <ApolloWrapper token={token}>
        <App config={_config} />
      </ApolloWrapper>
      {/* <AlertContainer /> */}
      {/* <LoaderContainer/> */}
    </MainContext.Provider>
  );
}
