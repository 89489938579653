import React from 'react';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  /*, createHttpLink, setContext, ApolloLink, onError, withClientState*/
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import { graphql_public_url, graphql_webapp_url } from '../../env';

/**************************************************
 * CLIENTE PUBLICO
 **************************************************/
export const APOLLO_PUBLIC_CLIENT = new ApolloClient({
  uri: graphql_public_url,
  cache: new InMemoryCache()
});

/**************************************************
 * CLIENTE DE USUARIOS AUTENTICADOS
 **************************************************/
// Creación de cache y manejo de uniones
//const cache = new InMemoryCache(/*{fragmentMatcher}*/);

// Link del servidor
/*const httpLink = createHttpLink({
  uri: graphql_webapp_url
  //useGETForQueries: false
});*/

// Upload link
/*const uploadLink = createUploadLink({
  uri: graphql_webapp_url, // Apollo Server is served from port 4000
  headers: {
    "keep-alive": "true"
  }
})*

// Manejo de errores
//TODO

// Inyección de token de autenticaciión

//DEFAULT
/*export const APOLLO_CLIENT = new ApolloClient({
    uri: graphql_webapp_url,
    cache: new InMemoryCache()
});*/

/*export const APOLLO_CLIENT = new ApolloClient({
  //link: authLink.concat(errorLink.concat(httpLink)),
  //link: authLink.concat(httpLink),
  link: httpLink,
  cache: cache,
  headers:{ 'Authorization':`Bearer ${existentToken}` },
  resolvers: {}
});
*/

export default function ApolloWrapper(props) {
  const APOLLO_CLIENT = new ApolloClient({
    uri: graphql_webapp_url,
    cache: new InMemoryCache(),
    link: createUploadLink({
      uri: graphql_webapp_url,
      headers: {
        "Authorization": `Bearer ${props.token}`,
        "keep-alive": "true"
      }
    })
  });
  //console.log("RENDER APOLLO WRAPPER")
  return (
    <ApolloProvider client={APOLLO_CLIENT}>
      {props.children}
    </ApolloProvider>
  )
}
