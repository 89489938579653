import React from 'react'
import reactDom from 'react-dom/client'
import App from './App'

//removing render from v17
//render(<App />, document.getElementById('root'))

//render app for react v18
const root = reactDom.createRoot(document.getElementById('root'));
root.render(<App />);
