/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))

const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const About = lazy(() => import('../pages/About'))
const Home = lazy(() => import('../pages/Home/Home'))

/********************************************************************************
 **** USERS VIEWS
 ********************************************************************************/

/********************************************************************************
 **** COMPANIES VIEWS
 ********************************************************************************/

/********************************************************************************
 **** PRODUCTS VIEWS
 ********************************************************************************/

/********************************************************************************
 **** STOCK VIEWS
 ********************************************************************************/

/********************************************************************************
 **** QUOTES VIEWS
 ********************************************************************************/

/********************************************************************************
 **** CONFIG VIEWS
 ********************************************************************************/
const AccesControl = lazy(() => import('../pages/Config/AccessControl/AccessControl'))

/********************************************************************************
 **** DEMOS VIEWS
 ********************************************************************************/
const DialogDemo = lazy(() => import('../pages/DialogDemo/DialogDemo'))
const ToastDemo = lazy(() => import('../pages/ToastDemo/ToastDemo'))
const FilterDemo = lazy(() => import('../pages/FilterDemo'))
const ListPageDemo = lazy(() => import('../pages/ListPageDemo'))
const TabsDemo = lazy(() => import('../pages/TabsDemo'))
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'))

const routes = [
  //LOGIN
  {
    path: '/signin',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignIn redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/signup',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <SignUp redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },
  {
    path: '/password_reset',
    exact: true,
    element: (
      <UnauthorizedRoute>
        <PasswordReset redirectTo="/home" />
      </UnauthorizedRoute>
    ),
  },

  //COMPANIES
  //PRODUCTS
  //STOCK
  //QUOTES
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  //USERS
  {
    path: '/my_account',
    exact: true,
    element: (
      <AuthorizedRoute>
        <MyAccount />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/home',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Home />
      </AuthorizedRoute>
    ),
  },
  //DEMOS
  {
    path: '/dialog_demo',
    exact: true,
    element: (
      <AuthorizedRoute>
        <DialogDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/toast_demo',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ToastDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/filter_demo',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FilterDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/list_page_demo',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ListPageDemo />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/tabs_demo',
    exact: true,
    element: (
      <AuthorizedRoute>
        <TabsDemo />
      </AuthorizedRoute>
    ),
  },
  //SETTINGS
  {
    path: '/access_control',
    exact: true,
    element: (
      <AuthorizedRoute>
        <AccesControl />
      </AuthorizedRoute>
    ),
  },
  //Default not found path (404)
  {
    path: '*',
    //exact: true,
    element: (
      <PageNotFound />
    ),
  },
]

export default routes
